/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.slim.min.js
 * - /npm/popper.js@1.16.0/dist/umd/popper.min.js
 * - /npm/bootstrap@4.3.1/dist/js/bootstrap.min.js
 * - /npm/pikaday@1.8.0/pikaday.min.js
 * - /npm/lazysizes@5.1.2/lazysizes.min.js
 * - /npm/photoswipe@4.1.3/dist/photoswipe.min.js
 * - /npm/photoswipe@4.1.3/dist/photoswipe-ui-default.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
